import config from "../ep.config.json";
import { httpGet, httpPost } from "./HttpService";

const {
  // DaikinApi,
  cortexApi: { scope }
} = config;

const options: any = {
  headers: {
    Authorization: `Bearer ${config.connectServicesToken}`
  }
};

/**
 * ## getPartsAndManifests
 *
 * @param sku: string;
 * @param serialNumber: string;
 *
 * @description Function that returns parts and manifests for specific sku and serial numbers
 */
export const getPartsAndManifests = (
  sku: string,
  serialNumber: string
): Promise<any> => {
  const url: string = `/connect/1.0/parts?sku=${sku}&serial=${serialNumber}`;

  return httpGet(url, options);
};

/**
 * ## listStatements
 *
 * @param customerNumber: string;
 *
 * @description Function that lists all avaliable statements for the user.
 */
// eslint-disable-next-line import/prefer-default-export
export const listStatements = (customerNumber: string): Promise<any> => {
  const url: string = `/connect/1.0/account/statement/list?customerNumber=${customerNumber}`;
  return httpGet(url, options);
};

/**
 * ## getAccountStatement
 * @param customerNumber: string;
 * @param statementDate: string;
 *
 * @description Function returns account statements for specified time period.
 */
// eslint-disable-next-line import/prefer-default-export
export const getAccountStatement = async (
  customerNumber: string,
  statementDate: string
): Promise<any> => {
  const url = `/connect/1.0/account/statement?customerNumber=${customerNumber}&statementDate=${statementDate}&format=PDF`;

  return httpGet(url, { ...options, responseType: "blob" });
};

/**
 * ## sendContactUsForm
 *
 * @param body string: - stringified message;
 *
 * @description Function sends the specified message to the "Contact Us" recipient
 * appropriate to the brand. Message is specified in a JSON body.
 * Brand must be Enum: "DAIKIN" | "GOODMAN" | "AMANA". (Daikin API 1.5)
 */
export const sendContactUsForm = async (body): Promise<any> => {
  const url = "/connect/2.0/contactus";
  try {
    const response = await httpPost(url, body, options);

    return response;
  } catch (err) {
    return err;
  }
};

/**
 * ## getWarrantyModels
 *
 * @param serialNumber: string;
 *
 * @description Function returns models for the supplied serial number
 */
export const getWarrantyModels = (serialNumber: string): Promise<any> => {
  const url = `/connect/2.0/warranty/models?serialNumber=${serialNumber}`;
  return httpGet(url, options);
};

/**
 * ## getWarrantyEntitlements
 *
 * @param serialNumber: string;
 * @param model: string;
 * @param installType: string;
 * @param lastName: string;
 *
 * @description Function returns warranty entitlements
 */
export const getWarrantyEntitlements = (
  serialNumber: string,
  model: string,
  installType: string,
  lastName: string
): Promise<any> => {
  const queryParams: string = `?serialNumber=${serialNumber}&model=${model}&installType=${installType}&lastName=${lastName ||
    ""}`;
  const url = `/connect/2.0/warranty/entitlements${queryParams}`;
  return httpGet(url, options);
};

/* ## getAssets
 *
 * @param skus: string
 *
 * @description Function that returns product assets (documents)
 */
export const getAssets = async (skus: string): Promise<any> => {
  const url = `/jsonrpc`;
  const body = {
    jsonrpc: "2.0",
    id: 0,
    method: "documentLibrary.getAssets",
    params: {
      skus: [skus]
    }
  };
  const response = await httpPost(url, body, options);

  return response;
};

export const getTaxonomy = async (): Promise<any> => {
  const url = `/jsonrpc`;
  const body = {
    jsonrpc: "2.0",
    id: 0,
    method: "documentLibrary.getTaxonomy",
    params: {}
  };
  const response = await httpPost(url, body, options);

  return response;
};
